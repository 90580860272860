import React from 'react';
import PlaceImage from '../Blocks/PlaceImage/placeImage';

import './fullscreenPhotoSection.css';

interface FullscreenPhotoSectionProps {
  alt: string;
  fileURL: string;
  dataSal?: string;
  salEasing?: string;
  salDelay?: string;
  notgatsbyimage?: boolean;
}

const FullscreenPhotoSection = ({ alt, fileURL, dataSal, salDelay, salEasing, notgatsbyimage }: FullscreenPhotoSectionProps) => {
  return (
        <section data-sal-easing={salEasing} data-sal={dataSal} data-sal-delay={salDelay} className="FullscreenPhotoSection">
            {notgatsbyimage 
            ? <img className="FullscreenPhotoSection-image" src={fileURL} alt={alt}/>
            : <PlaceImage imageClass="FullscreenPhotoSection-image" filename={fileURL} alt={alt}/>}
        </section>
  );
};

export default FullscreenPhotoSection;
