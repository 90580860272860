import React from 'react';

import './videoFullscreen.css';

interface VideoFullscreenProps {
  youtubeVideoID: string;
  margins?: boolean;
  dataSal?: string;
  salDelay?: string;
  salEasing?: string;
}

const VideoFullscreen = ({ youtubeVideoID, dataSal, salDelay, salEasing, margins }: VideoFullscreenProps) => {
  return (
        <iframe style={margins ? {margin: "50px 0 50px 0" } : {margin: 0} } data-sal-easing={salEasing} data-sal={dataSal} data-sal-delay={salDelay} className="VideoFullscreen" src={`https://www.youtube.com/embed/${youtubeVideoID}?autoplay=1&mute=1&loop=1&controls=0`} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
        </iframe>
  );
};

export default VideoFullscreen;
