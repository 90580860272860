import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React from 'react';
import HeroSection from '../components/Blocks/HeroSection/heroSection';
import Paragraph from '../components/Blocks/Paragraph/paragraph';
import PlaceImage from '../components/Blocks/PlaceImage/placeImage';
import Section from '../components/Blocks/Section/section';
import FullscreenPhotoSection from '../components/FullscreenPhotoSection/fullscreenPhotoSection';
import Layout from '../components/layout';
import PerformanceSection from '../components/PerformanceSection/performanceSection';
import PhotoLeftTextRight from '../components/PhotoLeftTextRight/photoLeftTextRight';
import SEO from '../components/SEO/seo';
import VideoFullscreen from '../components/VideoFullscreen/videoFullscreen';

const PolytechPage = () => {    
    return (
    <Layout >
       <SEO lang="en" title={'Design for Tsimmes'} keywords={['design', 'UX', 'UI', 'Artyom Alekseev', 'Артём Алексеев', 'website', 'usability', 'tsimmes']} description="Designing a mobile-first website with great UX">
        </SEO>

        <HeroSection animationType="noanima" heading="Redesigning website for Tsimmes" desc="Designing a mobile-first website with great UX."/>

        <Section heading="Project">
            <Paragraph>Tsimmes (Цимес) — it’s a media website for Jews and by Jews. But how they differ from other such websites it’s not only for Jews, it’s for everyone — everyone who likes to know more about the culture, cuisine, and customs of Jews.</Paragraph>
            <Paragraph>Great people from Tsimmes kindly asked me to help them with a redesign of their website.</Paragraph>
        </Section>

        <Section heading="Mobile-first">
            <Paragraph>The main point of redesigning of website it’s a focus on analytics, and it clearly shows that most people that visit the website — people on mobile devices. So mobile-first it’s a top priority.</Paragraph>
        </Section>

        <FullscreenPhotoSection salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" fileURL="tsimmes_mobilefirst.png" alt="Based on analytics we mind that it's should be mobile friendly"/>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="UI-kit" >
            <Paragraph>To maximize the speed of development, we need to mind all of the states what inputs, buttons, and other UI have. So we need a UI kit, that clearly shows all the interactions.</Paragraph>
            <PlaceImage filename="uiTsimmes.png" alt="Component library for Tsimmes's website"/>
        </Section>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" >
            <Paragraph>And we got this design, which, of course, needs to be sharpened by new features and incoming changes. But with a ready UI kit and flexible designs, it’s much easier to iterate and update the design.</Paragraph>
        </Section>

        <VideoFullscreen salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" youtubeVideoID="rbKKU3rYbuY"/>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300">
            <Paragraph>Thank you!</Paragraph>
        </Section>

    </Layout>
)};

export default PolytechPage;
